<template>
  <div class="w-100">
    <div class="global-green flex ac-c center-xs pt-30 pb-10" >
      <div class="flex w-100 jc-c">
        <h2 class="col-xs-12 mt-30 mb-15">Benchmarks</h2>
        <h3 class="col-xs-12 mb-30">Compare, test and stress your devices</h3>
        <div class="flex w-90 list-max-width">
          <list origin="benchmarks" :listItems="data.benchmarksData" @open-benchmark-from-tile="openBenchmark"></list>
        </div>
      </div>
<!--      <div class="button-small-more mt-15 mb-30 pl-10 pr-10">Filter</div>-->
      <div class="button-small-more col-xs-2 col-md-1 mt-15 mb-30 ml-15 pl-10 pr-10" @click="showAll('benchmarks')">Show all</div>
    </div>
    <div class="flex w-100 center-xs mt-30 mb-30">
      <div class="flex col col-xs-8 col-md-5">
        <h2 class="mt-30 mb-15">Customize benchmarks</h2>
        <h3 class="mb-30">Test and compare devices and browsers. Create your own tests for tailored results</h3>
        <div class="button-solid-green-70 flex jc-c ai-c mb-30" @click="createBenchmark">
          <span>Create benchmark</span>
        </div>
      </div>
    </div>
    <div class="global-blue flex jc-c ac-c center-xs pt-30 pb-10" >
      <div class="flex w-100 jc-c">
        <h2 class="col-xs-12 mt-30 mb-15">Available tests</h2>
        <h3 class="col-xs-12 mb-30">Compare, test and stress your devices</h3>
        <div class="flex w-90  center-xs center-md list-max-width">
          <list origin="tests" :listItems="data.testsData"></list>
        </div>
      </div>
<!--      <div class="button-small-more mt-15 mb-30 pl-10 pr-10">Filter</div>-->
      <div class="button-small-more col-xs-2 col-md-1 mt-15 mb-30 ml-15 pl-10 pr-10" @click="showAll('tests')">Show all</div>
    </div>
  </div>

  <benchmark-edit-popup v-if="data.benchmark.editVisible" :tile-id="data.benchmark.tileId" @close="closeEditBenchmarkPopup"></benchmark-edit-popup>
  <benchmark-create-popup v-if="data.benchmark.createVisible" @close="closeCreateBenchmarkPopup"></benchmark-create-popup>
  <show-all-popup v-if="show.showAllVisible" :data="show.data" :about="show.about" @close="show.showAllVisible = false" @reload-data="refreshData"></show-all-popup>
  <!-- <test-edit-popup v-show="data.test.editVisible" :tile-id="data.test.tileId" @close="data.test.editVisible = false"></test-edit-popup> -->

  <!--  <benchmark-popup v-show="data.isModalVisible" :tile-id="data.tileId" @close="data.isModalVisible = false"></benchmark-popup>-->
</template>
<script setup>

// register modal component
import {List, BenchmarkEditPopup, BenchmarkCreatePopup, ShowAllPopup} from '@/components'
import { reactive, onBeforeMount, inject, watch } from 'vue'
import { get } from "@/lib/api"
import { Notify  } from 'vant';

const  maxTilesInList = inject('maxTilesInList')

const data = reactive({
  benchmarksData: [],
  testsData: [],
  benchmark: {
    tileId: null,
    editVisible: false,
    createVisible: false,
    completeData: []
  },
  test:{
    completeData: []
  }
  /*
  test: {
    tileId: null,
    editVisible: false,
    createVisible: false
  }
  */
})

const disableNonMVPFeatures = inject('disableNonMVPFeatures')
function createBenchmark() {
  disableNonMVPFeatures ? showNotify() : data.benchmark.createVisible = true
}

function openBenchmark(item) {
  if(disableNonMVPFeatures) {
    showNotify()
  } else {
    data.benchmark.editVisible = true
    data.benchmark.tileId = item.id
  }
}

/*
Removing the functionality to create or edit tests
function openTest(item) {
  data.test.editVisible = true
  data.test.tileId = item.id
}
*/
// function navigateToCreateTest() {
//     router.push({path: '/tests/create'})
// }

function closeCreateBenchmarkPopup(){
  data.benchmark.createVisible = false
  getBenchmarks()
}

function closeEditBenchmarkPopup(isBenchmarkUpdated){
  data.benchmark.editVisible = false
  if (isBenchmarkUpdated) getBenchmarks()
}

const show = reactive({
  showAllVisible: false,
  about: '',
  data: []
})

function showAll(about){
  show.about = about
  show.showAllVisible = true
  show.data = about == 'benchmarks' ? data.benchmark.completeData : data.test.completeData
}

function refreshData(){
  show.about == 'benchmarks' ? getBenchmarks() : getTests()
}

watch(() => data.benchmark.completeData,  async (nV) => {
  if(show.showAllVisible) {
    show.data = nV;
  }
})

async function getBenchmarks(){
  try {
    let res = await get('users/me/benchmarks', true)
    data.benchmark.completeData = res.data;
    data.benchmarksData = res.data.length > maxTilesInList ? res.data.slice(0, maxTilesInList) : res.data
  } catch(error){
    console.error("dataFetchError : " + error)
  }
}

async function getTests(){
  try {
    let res = await get('users/me/tests', true)
    data.test.completeData = res.data
    data.testsData = res.data.length > maxTilesInList ? res.data.slice(0, maxTilesInList) : res.data
  } catch(error){
    console.error("dataFetchError : " + error)
  }
}

onBeforeMount(async () => {
     await getBenchmarks()
     await getTests()
    })

const infoMessage = inject('disabledFeaturesMessage')
function showNotify() {
  Notify ({type: 'warning', message: infoMessage, duration:1500})
}
</script>